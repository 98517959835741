import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.component.html',
  styleUrls: ['./policy-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyPageComponent implements OnInit {
  isShowAgreement = false;
  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }
}
