export const icons: Icon[] = [
  {
    name: 'error',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M8.25,21,3,15.75V8.25L8.25,3h7.5L21,8.25v7.5L15.75,21Zm.9-4.75L12,13.4l2.85,2.85,1.4-1.4L13.4,12l2.85-2.85-1.4-1.4L12,10.6,9.15,7.75l-1.4,1.4L10.6,12,7.75,14.85ZM9.1,19h5.8L19,14.9V9.1L14.9,5H9.1L5,9.1v5.8ZM12,12Z"/>
        <rect width="24" height="24" fill="none"/>
      </svg>`,
  },
  {
    name: 'note',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M10.6,16.6l7.05-7.05-1.4-1.4L10.6,13.8,7.75,10.95l-1.4,1.4ZM12,22a9.732,9.732,0,0,1-3.9-.788A9.978,9.978,0,0,1,2.788,15.9,9.732,9.732,0,0,1,2,12a9.732,9.732,0,0,1,.788-3.9A9.984,9.984,0,0,1,8.1,2.787,9.743,9.743,0,0,1,12,2a9.743,9.743,0,0,1,3.9.787A9.984,9.984,0,0,1,21.212,8.1,9.732,9.732,0,0,1,22,12a9.732,9.732,0,0,1-.788,3.9A9.978,9.978,0,0,1,15.9,21.212,9.732,9.732,0,0,1,12,22Zm0-10Zm0,8a7.714,7.714,0,0,0,5.663-2.337A7.714,7.714,0,0,0,20,12a7.714,7.714,0,0,0-2.337-5.663A7.714,7.714,0,0,0,12,4,7.711,7.711,0,0,0,6.338,6.337,7.713,7.713,0,0,0,4,12a7.713,7.713,0,0,0,2.338,5.663A7.711,7.711,0,0,0,12,20Z"/>
        <rect width="24" height="24" fill="none"/>
      </svg>`,
  },
  {
    name: 'warning',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M12,12.5ZM1,21,12,2,23,21Zm10-6h2V10H11Zm1,3a.984.984,0,1,0-.712-.288A.968.968,0,0,0,12,18ZM4.45,19h15.1L12,6Z"/>
        <rect width="24" height="24" fill="none"/>
      </svg>`,
  },
  {
    name: 'cross',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill=""/>
          </svg>`,
  },
];
interface Icon {
  name: string;
  icon: string;
}
