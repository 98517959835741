<div class="footer">
    <p>{{ currentDate.getFullYear() }} ООО "42"</p>
    <a class="footer__policy" routerLink="/policy"
        >Политика в отношении обработки<br />персональных данных</a
    >
    <div>
        <a class="footer__mail" href="mailto:info@answer-42.ru">info@answer-42.ru</a>
        <br />
        <a class="footer__tel" href="tel:+74993424200">+7 495 137 42 42</a>
    </div>
</div>
