import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgreementDto } from '../models/agreementDto.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  url = 'https://api.telegram.org/bot2098635478:AAGXC0R1j8-PGWRfYMscDvU416sATBdZ_4M/sendMessage';
  chatId = '-1001742223469';
  chatIdAgreement = '-777707351';
  // for testing
  // url = 'https://api.telegram.org/bot5170379193:AAHNDFYuIG9PsvyNpRwoKcQ6UsHlTE50QQo/sendMessage';
  // chatIdAgreement = '-1001663136884';

  constructor(private http: HttpClient) { }

  sendMessage(message: string): Observable<any> {
    return this.http.get<string>(this.url,
      { params: {
        chat_id: this.chatId,
        text: message,
        parse_mode: 'markdown'
    }});
  }

  sendAgreement(message: string): Observable<any> {
    return this.http.get<string>(this.url, 
      { params: {
        chat_id: this.chatIdAgreement,
        text: message,
        parse_mode: 'markdown'
    }});
  }

  sendAgeementToDb(body: AgreementDto): Observable<any> {
    return this.http.post('https://v2.answer-42.ru/api/agreement/', body)
  }
}
