<div class="products">
    <h2 class="title title__white">
        Сейчас нас около <span class="title__black">300 человек</span>
        <br />
        и мы делаем три больших продукта
    </h2>
    <div *ngIf="!isSmallScreen" class="slider">
        <button class="slider__btn" (click)="owlCar.prev()">
            <img class="slider__arrow slider__rotate" src="assets/arrow-btn.svg" />
        </button>
        <button class="slider__btn" (click)="owlCar.next()">
            <img class="slider__arrow" src="assets/arrow-btn.svg" />
        </button>
    </div>
    <div *ngIf="isSmallScreen">
        <ng-container *ngTemplateOutlet="slide1"></ng-container>
        <ng-container *ngTemplateOutlet="slide2"></ng-container>
        <ng-container *ngTemplateOutlet="slide3"></ng-container>
    </div>
    <owl-carousel-o [options]="customOptions" #owlCar>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide1"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide2"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide3"></ng-container>
        </ng-template>
    </owl-carousel-o>
</div>

<ng-template #slide1>
    <div class="product">
        <div class="product__description">
            <p class="product__title">Единое хранилище данных</p>
            <p class="product__text">
                Однажды Соня рассказывала про ЕХД на конференции,
                <br />
                <a target="_blank" href="https://www.youtube.com/watch?v=bNcxYdwY64M"
                    >там сильно более подробно
                </a>
            </p>
            <p class="product__text">Собственный продукт, который мы развиваем уже 7 лет</p>
        </div>
        <div class="product__edge">
            <div class="plus">
                <p class="plus__title">Гибкая настройка структур данных</p>
                <p class="plus__text">
                    с использованием логических условий проверки, автоматического изменения и
                    заполнения значений, работы с геометрией; настройка и отправка уведомлений
                </p>
            </div>
            <div class="plus">
                <p class="plus__title">Связывание наборов данных</p>
                <p class="plus__text">
                    соединение множеств по условиям или MDM (создание «золотых записей»)
                </p>
            </div>
        </div>
        <div class="product__edge">
            <div class="plus">
                <p class="plus__title">Работа с данными</p>
                <p class="plus__text">
                    ввод через интерфейс, импорт файлов, интеграции через REST-и SOAP-сервисы на
                    чтение и запись, публикация на портале открытых данных, отчёты и выгрузки
                </p>
            </div>
            <div class="plus">
                <p class="plus__title">Витрина качества данных</p>
                <p class="plus__text">настройка комплекса проверок и нормализации для атрибута</p>
            </div>
        </div>
        <div class="product__logos">
            <img class="" src="assets/java.png" />
            <img class="" src="assets/glassfish.png" />
            <img class="" src="assets/postgre.png" />
            <img class="" src="assets/oracle.png" />
            <img class="" src="assets/php-fpm.png" />
            <img class="" src="assets/spring.png" />
        </div>
    </div>
</ng-template>

<ng-template #slide2>
    <div class="product">
        <div class="product__description">
            <p class="product__title">Образование</p>
            <p class="product__text">
                Отдельные продукты в составе крупного проекта автоматизации образования Москвы
            </p>
        </div>
        <div class="product__edge">
            <div class="plus">
                <p class="plus__title">Электронные журнал и дневник</p>
                <p class="plus__text">
                    вся информация для ученика и учителя: учебные планы, расписание, посещаемость,
                    успеваемость и аналитика (веб и мобильное приложение)
                </p>
            </div>
            <div class="plus">
                <p class="plus__title">Дистанционное обучение</p>
                <p class="plus__text">
                    организация, планирование, выбор платформы видео-конференц-связи для
                    использования
                </p>
            </div>
        </div>
        <div class="product__edge">
            <div class="plus">
                <p class="plus__title">Здоровье преподавателей и учеников</p>
                <p class="plus__text">
                    сбор общих сведений о состоянии здоровья, выявление групп риска на основе
                    анализа данных
                </p>
            </div>
            <div class="plus">
                <p class="plus__title">Библиотека</p>
                <p class="plus__text">
                    хранилище образовательных электронных материалов и инструментов (веб и мобильное
                    приложение)
                </p>
            </div>
        </div>
        <div class="product__logos">
            <img class="" src="assets/java.png" />
            <img class="" src="assets/react.png" />
            <img class="" src="assets/postgre.png" />
            <img class="" src="assets/angular.png" />
            <img class="" src="assets/kafka.png" />
            <img class="" src="assets/spring.png" />
        </div>
    </div>
</ng-template>

<ng-template #slide3>
    <div class="product">
        <div class="product__description">
            <p class="product__title">Медицина</p>
            <p class="product__text">
                Отдельные продукты в составе крупного проекта автоматизации здравоохранения Москвы
            </p>
        </div>
        <div class="product__edge">
            <div class="plus">
                <p class="plus__title">COVID-19</p>
                <p class="plus__text">
                    учет тестов на инфекцию и их результатов, взаимодействие с сервисами лабораторий
                </p>
            </div>
            <div class="plus">
                <p class="plus__title">Управление справочниками</p>
                <p class="plus__text">хранение, ведение, предоставление доступа</p>
            </div>
        </div>
        <div class="product__edge">
            <div class="plus">
                <p class="plus__title">Ведение ключевых реестров</p>
                <p class="plus__text">работников, организаций, оборудования и прочего</p>
            </div>
            <div class="plus">
                <p class="plus__title">Вызов врача на дом</p>
                <p class="plus__text">
                    полный учёт посещений и их результатов через мобильное приложение на планшетах
                    врачей
                </p>
            </div>
        </div>
        <div class="product__logos">
            <img class="" src="assets/java.png" />
            <img class="" src="assets/react-redux.png" />
            <img class="" src="assets/postgre.png" />
            <img class="" src="assets/oracle.png" />
            <img class="" src="assets/kafka.png" />
        </div>
        <div class="product__logos">
            <img class="" src="assets/rabbitmq.png" />
            <img class="" src="assets/rails.png" />
            <img class="" src="assets/solr.png" />
            <img class="" src="assets/phoenix.png" />
            <img class="" src="assets/spring.png" />
        </div>
    </div>
</ng-template>
