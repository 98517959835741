import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ReportService } from 'src/app/services/report.service copy';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() isProject = false;
  @Output() closeModal = new EventEmitter<boolean>();
  public isShowPolicy = false;
  public sendingForm: UntypedFormGroup;
  count = 0;
  $isShowPolicy = this.policyService.isShowPolicyModal;
  isAgree = false;
  private subscription = new Subscription();

  @ViewChild('textarea') textarea!: ElementRef;

  constructor(
    private sendMessageService: MessageService,
    private renderer: Renderer2,
    private policyService: PolicyService,
    private reportservice: ReportService,
  ) {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '15px';
    this._createForm();
    this.sendingForm.get('phone').valueChanges.subscribe((v: string) => {
      if (v.length > 20) {
        this.sendingForm.patchValue({ phone: v.substring(0, 20) });
      }
      if (v.match(/[^0-9\-\(\)\+ ]/g)) {
        this.sendingForm.controls['phone'].setValue(v.replace(/[^0-9\-\(\)\+ ]/g, ''));
      }
      if (v.length == 0) {
        this.sendingForm.controls['phone'].setValue('+7 (');
      }
    });
  }

  resize(): void {
    this.count = this.sendingForm.value.description.length;
    if (this.count >= 2000) {
      this.sendingForm.controls['description'].setValue(
        this.sendingForm.value.description.substring(0, 1999),
      );
    }
    const height = this.textarea.nativeElement.scrollHeight;
    if (height < 224) {
      this.renderer.setStyle(this.textarea.nativeElement as HTMLElement, 'height', 'auto');
      this.renderer.setStyle(
        this.textarea.nativeElement as HTMLElement,
        'height',
        this.textarea.nativeElement.scrollHeight + 1 + 'px',
      );
    }
  }

  public send(): void {
    if (this.sendingForm.status === 'INVALID') {
      return;
    }
    this.sendAgreement();
    this.sendingForm.value.email = this.addShieldToSymbol(this.sendingForm.value.email);
    this.sendingForm.value.surname = this.addShieldToSymbol(this.sendingForm.value.surname);
    this.sendingForm.value.name = this.addShieldToSymbol(this.sendingForm.value.name);
    this.sendingForm.value.description = this.addShieldToSymbol(this.sendingForm.value.description);
    if (this.isProject) {
      const message =
        '*Запрос с формы «Опишите ваш проект»*\n\n*Имя:* ' +
        this.sendingForm.value.name +
        '\n*Фамилия:* ' +
        this.sendingForm.value.surname +
        '\n*E-mail:* ' +
        this.sendingForm.value.email +
        '\n*Номер телефона:* ' +
        this.sendingForm.value.phone +
        '\n\n*Описание задачи:*\n' +
        this.sendingForm.value.description;
      this.subscription.add(
        this.sendMessageService.sendMessage(message).subscribe({
          next: () => {
            this.reportservice.message.next({
              type: 'note',
              title: '',
              text: 'Запрос успешно отправлен',
            });
            this.onClose();
          },
          error: (err) => {
            this.reportservice.message.next({
              type: 'error',
              title: '',
              text: 'Запрос не отправлен, попробуйте снова',
            });
            // this.onClose();
          },
        }),
      );
    } else {
      const message =
        '*Запрос с формы «Стать частью команды»*\n\n*Имя:* ' +
        this.sendingForm.value.name +
        '\n*Фамилия:* ' +
        this.sendingForm.value.surname +
        '\n*E-mail:* ' +
        this.sendingForm.value.email +
        '\n*Номер телефона:* ' +
        this.sendingForm.value.phone +
        '\n\n*О себе:*\n' +
        this.sendingForm.value.description;
      this.subscription.add(
        this.sendMessageService.sendMessage(message).subscribe({
          next: () => {
            this.reportservice.message.next({
              type: 'note',
              title: '',
              text: 'Запрос успешно отправлен',
            });
            this.onClose();
          },
          error: (err) => {
            this.reportservice.message.next({
              type: 'error',
              title: '',
              text: 'Запрос не отправлен, попробуйте снова',
            });
            // this.onClose();
          },
        }),
      );
    }
  }

  sendAgreement(): void {
    this.subscription.add(
      this.sendMessageService
        .sendAgeementToDb({
          name: this.sendingForm.value.name,
          surname: this.sendingForm.value.surname,
          email: this.sendingForm.value.email,
          phone: this.sendingForm.value.phone,
        })
        .subscribe({
          next: () => {},
          error: (err) => {
            console.log('Agreement send to db error', err);
          },
        }),
    );
    const message =
      '*Согласие на обработку персональных данных*\n\n*Имя:* ' +
      this.sendingForm.value.name +
      '\n*Фамилия:* ' +
      this.sendingForm.value.surname +
      '\n*E-mail:* ' +
      this.sendingForm.value.email +
      '\n*Номер телефона:* ' +
      this.sendingForm.value.phone;
    this.subscription.add(
      this.sendMessageService.sendAgreement(message).subscribe({
        next: () => {},
        error: (err) => {
          console.log('Agreement send error', err);
        },
      }),
    );
  }

  addShieldToSymbol(text: string): string {
    if (text) {
      return text.replace(/[_*`\[]/g, '\\$&');
    } else {
      return '';
    }
  }

  public onClose(): void {
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0px';
    this.subscription.unsubscribe();
    this.closeModal.emit(true);
  }

  private _createForm(): void {
    this.sendingForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.min(2)]),
      surname: new UntypedFormControl(null, [Validators.required, Validators.min(2)]),
      email: new UntypedFormControl(null, [Validators.email, Validators.required]),
      phone: new UntypedFormControl(null, [
        Validators.pattern(/^((\+?\d?)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,18}$/),
        Validators.required,
      ]),
      description: new UntypedFormControl(null),
    });
  }
  focusOnPhone(): void {
    if (!this.sendingForm.value['phone']) {
      this.sendingForm.controls['phone'].setValue('+7 (');
    }
  }

  manualChangeDate(e: KeyboardEvent): void {
    if (e.key === 'Backspace') {
      // удаление символов
      return;
    }
    if (this.sendingForm.value['phone'].length === 2) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + ' (');
    }
    if (this.sendingForm.value['phone'].length === 7) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + ') ');
    }
    if (this.sendingForm.value['phone'].length === 12) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + '-');
    }
    if (this.sendingForm.value['phone'].length === 15) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + '-');
    }
  }
}
