import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PolicyService } from './services/policy.service';
import { ReportService } from './services/report.service copy';
import { icons } from 'src/assets/icons';

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  isShowPolicy = this.policyService.isShowPolicyModal;
  constructor(
    private policyService: PolicyService,
    private reportservice: ReportService,
    private iconnRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    icons.forEach((icon) => {
      iconnRegistry.addSvgIconLiteral(icon.name, sanitizer.bypassSecurityTrustHtml(icon.icon));
    });
  }
  ngOnInit(): void {
    this.reportservice.message.next({
      type: 'note',
      title: '',
      text: 'Мы коварно используем куки',
      showTime: 100000,
    });
  }
}
