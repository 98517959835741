import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../models/message.model';

@Injectable({
  providedIn: 'root',
})

/**
 * Сервис для отображения всплывающего окна с сообщениями
 * в message записывем новое значение, например  this.messageService.message.next({type:'error', title: 'Ошибка добавления роли', text:'Произошла такая то ошибка'});
 */
export class ReportService {
  public message = new Subject<Message>();
  constructor() {}
}
