import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent {
  // срыть / показать модальное окно
  public isShowModal = false;
  // хочет в команду или проект
  public isProject = true;

  constructor() { }

}
