import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficesComponent {
  // срыть / показать модальное окно
  public isShowModal = false;

  customOptions: OwlOptions = {
    margin: 15,
    nav: false,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    responsive: {
        0: {
          items: 1,
          stagePadding: 32,
        },
        550: {
          center: false,
          items: 2,
          stagePadding: 45,
        },
        771: {
          items: 0
        }
    }
  };

  constructor() { }
}
