import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-working-conditions',
  templateUrl: './working-conditions.component.html',
  styleUrls: ['./working-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingConditionsComponent {
  customOptions: OwlOptions = {
    center: true,
    margin: 15,
    nav: false,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    responsive: {
        0: {
            items: 1,
            stagePadding: 32,
        },
        500: {
          center: false,
          items: 2,
          stagePadding: 25,
        },
        600: {
          center: false,
          items: 3,
          stagePadding: 25,
        },
        771: {
          items: 0
        }
    }
  };
}
