import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, enableProdMode } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { AboutComponent } from './components/about/about.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsComponent } from './components/products/products.component';
import { OrderComponent } from './components/order/order.component';
import { OfficesComponent } from './components/offices/offices.component';
import { RemoteComponent } from './components/remote/remote.component';
import { WorkingConditionsComponent } from './components/working-conditions/working-conditions.component';
import { FooterComponent } from './components/footer/footer.component';
import { CitiesComponent } from './components/cities/cities.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PolicyComponent } from './components/policy/policy.component';
import { RouterModule, Routes } from '@angular/router';
import { SexismComponent } from './components/sexism/sexism.component';
import { MainComponent } from './components/main/main.component';
import { PolicyPageComponent } from './components/policy-page/policy-page.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { ReportComponent } from './components/report/report.component';
import { MatIconModule } from '@angular/material/icon';
import { DocumentsPageComponent } from './documents-page/documents-page.component';
import { HomagePageComponent } from './components/homage-page/homage-page.component';

const appRoutes: Routes = [
  { path: '', component: MainComponent },
  // { path: 'sexism', component: SexismComponent },
  { path: 'policy', component: PolicyPageComponent },
  { path: 'homage', component: HomagePageComponent },
  { path: 'documents', component: DocumentsPageComponent },
  { path: '**', component: MainComponent },
];
enableProdMode();
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AboutComponent,
    ModalComponent,
    ProductsComponent,
    OrderComponent,
    OfficesComponent,
    RemoteComponent,
    WorkingConditionsComponent,
    FooterComponent,
    CitiesComponent,
    PolicyComponent,
    SexismComponent,
    MainComponent,
    PolicyPageComponent,
    AgreementComponent,
    ReportComponent,
    DocumentsPageComponent,
    HomagePageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    CarouselModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
    }),
    MatIconModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
