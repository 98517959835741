<div class="head-wrapper">
    <app-about id="about"></app-about>
</div>
<div class="wrapper-products">
    <app-products id="products"></app-products>
</div>
<div class="wrapper">
    <app-order id="for-the-alliance"></app-order>
    <app-offices id="offices"></app-offices>
</div>
<app-remote id="remote"></app-remote>
<div class="footer">
    <app-working-conditions id="working-conditions"></app-working-conditions>
</div>
