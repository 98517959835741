<div class="container">
    <div>
        <div class="head">
            <p class="title">НАВЯЗ<span>Ч2</span>ВОЕ<br>ПРОСВЕЩЕНИЕ</p>
            <p class="text">Спецвыпуск на гендерные праздники для тех,<br>кто пропустил все мемасы интернетов</p>
        </div>
        <div class="mems">
            <div class="mems__col-3">
                <img src="assets/mems/8.gif">
                <img src="assets/mems/14.gif">
                <img src="assets/mems/2.gif">
                <img src="assets/mems/15.gif">
                <img src="assets/mems/13.gif">
                <img src="assets/mems/17.gif">
            </div>
            <div class="mems__col-3">
                <img src="assets/mems/16.gif">
                <img src="assets/mems/5.gif">
                <img src="assets/mems/10.gif">
                <img src="assets/mems/3.gif">
                <img src="assets/mems/7.gif">
            </div>
            <div class="mems__col-3">
                <img src="assets/mems/12.gif">
                <img src="assets/mems/6.gif">
                <img src="assets/mems/1.gif">
                <img src="assets/mems/9.gif">
                <img src="assets/mems/4.gif">
                <img src="assets/mems/11.gif">
            </div>
            <div class="mems__col-2">
                <img src="assets/mems/8.gif">
                <img src="assets/mems/14.gif">
                <img src="assets/mems/2.gif">
                <img src="assets/mems/15.gif">
                <img src="assets/mems/13.gif">
                <img src="assets/mems/17.gif">
                <img src="assets/mems/12.gif">
                <img src="assets/mems/6.gif">
                <img src="assets/mems/1.gif">
            </div>
            <div class="mems__col-2">
                <img src="assets/mems/16.gif">
                <img src="assets/mems/5.gif">
                <img src="assets/mems/10.gif">
                <img src="assets/mems/3.gif">
                <img src="assets/mems/7.gif">
                <img src="assets/mems/9.gif">
                <img src="assets/mems/4.gif">
                <img src="assets/mems/11.gif">
            </div>
            <div class="mems__col-1">
                <img src="assets/mems/8.gif">
                <img src="assets/mems/14.gif">
                <img src="assets/mems/2.gif">
                <img src="assets/mems/15.gif">
                <img src="assets/mems/13.gif">
                <img src="assets/mems/17.gif">
                <img src="assets/mems/12.gif">
                <img src="assets/mems/6.gif">
                <img src="assets/mems/1.gif">
                <img src="assets/mems/16.gif">
                <img src="assets/mems/5.gif">
                <img src="assets/mems/10.gif">
                <img src="assets/mems/3.gif">
                <img src="assets/mems/7.gif">
                <img src="assets/mems/9.gif">
                <img src="assets/mems/4.gif">
                <img src="assets/mems/11.gif">
            </div>
        </div>
    </div>
</div>
