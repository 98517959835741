import { BreakpointObserver } from '@angular/cdk/layout';
import { ViewportScroller } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isHideMenu = false;
  public isShowModal = false;
  public isShowMenu = false;
  public isSmallScreen = false;
  public isStickyHeader = false;
  public subscription: Subscription;
  public subscriptionBreakpoint: Subscription;
  private scrollPosition: number;

  constructor(
    private viewportScroller: ViewportScroller,
    private breakpointObserver: BreakpointObserver,
    private ref: ChangeDetectorRef,
  ) {
    this.subscriptionBreakpoint = this.breakpointObserver
      .observe('(max-width: 770px)')
      .subscribe((res) => {
        if (res.matches) {
          this.isSmallScreen = true;
        } else {
          this.isSmallScreen = false;
          this.isShowMenu = false;
        }
        this.getWidth();
        this.ref.markForCheck();
      });
  }

  public navigateToAnchor(route: string): void {
    this.isShowMenu = false;
    // this.viewportScroller.scrollToAnchor('/' + route);
  }

  ngOnInit(): void {
    this.subscription = fromEvent(window, 'scroll').subscribe((scroll) => {
      this.getWidth();
    });
  }

  private getWidth(): void {
    if (window.scrollY > 10) {
      this.isStickyHeader = true;
    } else {
      this.isStickyHeader = false;
    }

    this.scrollPosition = window.scrollY;
    this.ref.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionBreakpoint.unsubscribe();
  }
}
