import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-sexism',
  templateUrl: './sexism.component.html',
  styleUrls: ['./sexism.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SexismComponent {
}
