<div class="container">
    <h2 class="title title__black working-title">
        Почему у нас <span class="title_accent">хорошо</span>
    </h2>
    <div class="conditions">
        <ng-container *ngTemplateOutlet="slide1"></ng-container>
        <ng-container *ngTemplateOutlet="slide7"></ng-container>
        <ng-container *ngTemplateOutlet="slide8"></ng-container>
        <ng-container *ngTemplateOutlet="slide2"></ng-container>

        <ng-container *ngTemplateOutlet="slide4"></ng-container>
        <ng-container *ngTemplateOutlet="slide3"></ng-container>
        <ng-container *ngTemplateOutlet="slide5"></ng-container>
        <ng-container *ngTemplateOutlet="slide6"></ng-container>
    </div>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide1"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide7"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide8"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide2"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide4"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide3"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide5"></ng-container>
        </ng-template>
        <ng-template carouselSlide>
            <ng-container *ngTemplateOutlet="slide6"></ng-container>
        </ng-template>
    </owl-carousel-o>
</div>

<ng-template #slide1>
    <div class="condition">
        <img class="" src="assets/convert.png" />
        <p class="condition__text">
            Белая зарплата,<br />
            всё по ТК РФ
        </p>
    </div>
</ng-template>
<ng-template #slide2>
    <div class="condition">
        <img class="" src="assets/study.png" />
        <p class="condition__text">Проф. обучение и конференции, в том числе в других городах</p>
    </div>
</ng-template>
<ng-template #slide3>
    <div class="condition">
        <img class="" src="assets/dog.png" />
        <p class="condition__text">Сложные, современные и важные (правда) задачи</p>
    </div>
</ng-template>
<ng-template #slide4>
    <div class="condition">
        <img class="" src="assets/bonus.png" />
        <p class="condition__text">Бонусы по итогам<br />проектов</p>
    </div>
</ng-template>
<ng-template #slide5>
    <div class="condition">
        <img class="" src="assets/palm.png" />
        <p class="condition__text">Удалёнка,<br />выбор графика работы</p>
    </div>
</ng-template>
<ng-template #slide6>
    <div class="condition">
        <img class="" src="assets/corn.png" />
        <p class="condition__text">Командные развлечения,<br />включая поездки</p>
    </div>
</ng-template>
<ng-template #slide7>
    <div class="condition">
        <img class="" src="assets/it-company.png" />
        <p class="condition__text">Аккредитованная <br />IT-компания</p>
    </div>
</ng-template>
<ng-template #slide8>
    <div class="condition">
        <img class="" src="assets/social.png" />
        <p class="condition__text">Соцпакет - несколько опций на выбор</p>
    </div>
</ng-template>
