<div class="modal-wrap">
    <div class="agreement">
        <button (click)="close()" class="modal__close-btn">✕</button>
        <h2 class="agreement__title">Согласие на обработку персональных данных</h2>
        <form [formGroup]="sendingForm" class="modal-input">
            <input
                class="modal-input__item"
                placeholder="Ваше Имя*"
                type="text"
                formControlName="name"
            />
            <input
                class="modal-input__item"
                placeholder="Ваша Фамилия*"
                type="text"
                formControlName="surname"
            />
            <input
                class="modal-input__item"
                placeholder="E-mail"
                type="email"
                formControlName="email"
            />
            <input
                class="modal-input__item"
                placeholder="Номер телефона*"
                type="tel"
                formControlName="phone"
                (focus)="focusOnPhone()"
                (keydown)="manualChangeDate($event)"
            />
        </form>
        <div class="modal-footer">
            <div>
                <div class="modal-consent">
                    <button
                        class="modal-consent__checkbox"
                        [ngClass]="{ 'modal-consent__checkbox--selected': isAgree }"
                        (click)="isAgree = !isAgree"
                    ></button>
                    <p class="modal-consent__text">
                        Я даю
                        <a href="assets/documents/agreement_candidate.doc" download
                            >согласие на обработку своих персональных данных</a
                        >
                        для рассмотрения моей кандидатуры.
                    </p>
                </div>
                <div class="modal-consent">
                    <button
                        class="modal-consent__checkbox"
                        [ngClass]="{ 'modal-consent__checkbox--selected': isAgree2 }"
                        (click)="isAgree2 = !isAgree2"
                    ></button>
                    <p class="modal-consent__text">
                        Я даю
                        <a href="assets/documents/agreement_candidate.doc" download
                            >согласие на обработку своих персональных данных</a
                        >
                        для включения меня в кадровый резерв
                    </p>
                </div>
                <p class="modal-consent__text">
                    Ознакомиться с
                    <a (click)="showPolicy()"
                        >Политикой в отношении обработки персональных данных</a
                    >
                </p>
            </div>

            <button
                class="main-button"
                (click)="send()"
                [disabled]="!isAgree || !isAgree2 || sendingForm.invalid"
            >
                Отправить
            </button>
        </div>
    </div>
</div>
<div class="modal-fade"></div>
