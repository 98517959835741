import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Message } from 'src/app/models/message.model';
import { ReportService } from 'src/app/services/report.service copy';

// тип сообщения error | note | warning
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportComponent {
  message: Message | undefined;
  isShow = false;
  constructor(private reportService: ReportService, private cdr: ChangeDetectorRef) {
    this.reportService.message.subscribe((message) => {
      this.message = message;
      this.isShow = true;
      this.cdr.markForCheck();
      setTimeout(() => this.close(), this.message.showTime ? this.message.showTime : 5000);
    });
  }

  close() {
    this.isShow = false;
    this.cdr.markForCheck();
  }
}
