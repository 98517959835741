<div class="wrap">
    <div class="pad" (click)="onClose()"></div>
    <div class="modal">
        <button (click)="onClose()" class="modal__close">✕</button>
        <div *ngIf="isProject; else beTeam">
            <h2 class="modal__title">Опишите ваш проект</h2>
            <p class="modal__text">
                Мы напишем или позвоним, чтобы узнать больше о вашей задаче и договориться об
                интервью.
                <br />
                Если у вас остались вопросы — ответим подробно.
            </p>
        </div>
        <ng-template #beTeam>
            <h2 *ngIf="!isProject" class="modal__title">Стать частью команды</h2>
            <p class="modal__text">
                Расскажите немного о себе.
                <br />
                Мы напишем или позвоним, чтобы узнать о Вас больше и договориться об интервью. Если
                у Вас остались вопросы — ответим подробно.
            </p>
        </ng-template>
        <form [formGroup]="sendingForm" class="input">
            <input class="input__item" placeholder="Ваше имя*" type="text" formControlName="name" />
            <input
                class="input__item"
                placeholder="Ваша фамилия*"
                type="text"
                formControlName="surname"
            />
            <input class="input__item" placeholder="E-mail*" type="email" formControlName="email" />
            <input
                class="input__item"
                placeholder="Номер телефона*"
                type="tel"
                formControlName="phone"
                (focus)="focusOnPhone()"
                (keydown)="manualChangeDate($event)"
            />
            <label class="input__text"
                >{{ isProject ? 'Опишите Вашу задачу' : 'О себе'
                }}<span>{{ count }} / 2000 символов</span></label
            >
            <textarea
                #textarea
                class="input__textarea"
                formControlName="description"
                (input)="resize()"
            ></textarea>
        </form>
        <div class="modal-footer">
            <div class="modal-consent">
                <button
                    class="modal-consent__checkbox"
                    [ngClass]="{ 'modal-consent__checkbox--selected': isAgree }"
                    (click)="isAgree = !isAgree"
                ></button>
                <p class="modal-consent__text">
                    Я даю
                    <a
                        [href]="
                            isProject
                                ? 'assets/agreement_project.docx'
                                : 'assets/agreement_candidate.doc'
                        "
                        download
                        >согласие</a
                    >
                    на обработку своих персональных<br />
                    данных согласно
                    <a (click)="$isShowPolicy.next(true)"
                        >Политики в отношении<br />обработки персональных данных</a
                    >
                </p>
            </div>
            <button class="main-button" (click)="send()" [disabled]="!isAgree">Отправить</button>
        </div>
    </div>
</div>
<div class="fade"></div>
