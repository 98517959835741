<div class="wrap wrap__{{ style }}">
    <div *ngIf="style === 'modal'" class="pad" (click)="$isShowPolicy.next(false)"></div>
    <div class="modal modal__{{ style }}">
        <button *ngIf="style === 'modal'" (click)="$isShowPolicy.next(false)" class="modal__close">
            ✕
        </button>
        <div class="modal__head">
            <h1 class="modal__title">Политика в отношении обработки<br />персональных данных</h1>
        </div>

        <div class="policy">
            <h2 class="policy__title">1. Общие положения</h2>
            <p>
                Настоящая политика обработки персональных данных составлена в соответствии с
                требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных»
                (далее - Закон) и определяет порядок обработки персональных данных и меры по
                обеспечению безопасности персональных данных, предпринимаемые ООО «42» (далее –
                Оператор, Общество).
            </p>
            <p>
                Оператором для этих целей введен в действие комплект организационно-распорядительной
                документации, обязательный к исполнению всеми работниками Оператора, допущенными к
                обработке персональных данных.
            </p>
            <p>
                Оператор гарантирует соблюдение конфиденциальности в отношении полученных
                персональных данных с учетом требований Закона и положений настоящей Политики и
                обязуется использовать их только в целях, указанных в Политике.
            </p>
            <p>Обработка персональных данных осуществляется на основе принципов:</p>
            <ul>
                <li>законности и справедливости целей и способов обработки персональных данных;</li>
                <li>
                    соответствия целей обработки персональных данных целям, заранее определенным и
                    заявленным при сборе персональных данных;
                </li>
                <li>
                    соответствия объема и характера обрабатываемых персональных данных, способов их
                    обработки целям обработки персональных данных;
                </li>
                <li>
                    точности и актуальности персональных данных, их достаточности для целей
                    обработки, недопустимости обработки персональных данных, избыточных по отношению
                    к целям, заявленным при сборе персональных данных;
                </li>
                <li>
                    недопустимости объединения созданных для несовместимых между собой целей баз
                    данных, содержащих персональные данные.
                </li>
            </ul>
            <p>
                Настоящая политика Оператора по обработке персональных данных (далее – Политика)
                действует в отношении персональных данных посетителей сайта и соискателей.
            </p>
            <p>
                Пожалуйста, внимательно прочтите настоящий документ, чтобы понимать подход Общества
                к обработке Ваших персональных данных и то, как соответствующие данные используются
                Обществом.
            </p>
            <h2 class="policy__title">2. Основные понятия</h2>
            <p>
                «Оператор персональных данных» в контексте данной Политики — ООО «42» (ОГРН
                1207700295000, ИНН 9710084876), расположенное по адресу:127018, г. Москва, вн. тер.
                г. муниципальный округ Марьина Роща, ул. Полковая, д. 3, стр. 1, этаж/помещение 3/I,
                осуществляющее обработку персональных данных, а также определяющее цели обработки
                персональных данных, состав персональных данных, подлежащих обработке, действия
                (операции), совершаемые с персональными данными.
            </p>
            <p>
                «Веб-сайт» (далее — «сайт») — сайт https://answer-42.ru, на котором любой Посетитель
                может ознакомиться с услугами и продуктами Оператора, оставить обратную связь по
                открытым вакансиям у Оператора и др.
            </p>
            <p>«Пользователь» — любой Посетитель сайта https://answer-42.ru.</p>
            <p>
                «Персональные данные» (далее — «Персональные данные», «Данные») — любая информация,
                относящаяся прямо или косвенно к определенному или определяемому физическому лицу
                (субъекту персональных данных).
            </p>
            <p>
                «Биометрические персональные данные» — сведения, которые характеризуют
                физиологические и биологические особенности человека, на основании которых можно
                установить его личность и которые используются оператором для установления личности
                субъекта.
            </p>
            <p>
                «Работник» — физическое лицо, вступившее в трудовые отношения с работодателем на
                основании трудового договора и на иных основаниях, предусмотренных Трудовым кодексом
                Российской Федерации.
            </p>
            <p>
                «Субъект персональных данных» — физическое лицо, которое прямо или косвенно
                определено или определяемо с помощью персональных данных.
            </p>
            <p>
                «Обработка персональных данных» — любое действие (операция) или совокупность
                действий (операций), совершаемых с использованием средств автоматизации или без
                использования таких средств с персональными данными, включая сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                использование, передачу (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.
            </p>
            <p>
                «Автоматизированная обработка персональных данных» — обработка персональных данных с
                помощью средств вычислительной техники.
            </p>
            <p>
                «Распространение персональных данных» — действия, направленные на раскрытие
                персональных данных неопределенному кругу лиц.
            </p>
            <p>
                «Предоставление персональных данных» — действия, направленные на раскрытие
                персональных данных определенному лицу или определенному кругу лиц.
            </p>
            <p>
                «Блокирование персональных данных» — временное прекращение обработки персональных
                данных (за исключением случаев, если обработка необходима для уточнения персональных
                данных).
            </p>
            <p>
                «Уничтожение персональных данных» — действия, в результате которых становится
                невозможным восстановить содержание персональных данных в информационной системе
                персональных данных и (или) в результате которых уничтожаются материальные носители
                персональных данных.
            </p>
            <p>
                «Обезличивание персональных данных» — действия, в результате которых становится
                невозможным без использования дополнительной информации определить принадлежность
                персональных данных конкретному субъекту персональных данных.
            </p>
            <p>
                «Информационная система персональных данных» — совокупность содержащихся в базах
                данных персональных данных и обеспечивающих их обработку информационных технологий и
                технических средств.
            </p>
            <p>
                «Трансграничная передача персональных данных» — передача персональных данных на
                территорию иностранного государства органу власти иностранного государства,
                иностранному физическому лицу или иностранному юридическому лицу.
            </p>
            <p>
                «Конфиденциальность персональных данных» — обязательное для соблюдения Оператором
                или иным получившим доступ к персональным данным лицом требование не допускать их
                распространения без согласия субъекта персональных данных или наличия иного
                законного основания.
            </p>
            <p>
                «Куки» (Cookies) — небольшой фрагмент данных, отправленный веб-сервером и хранимый
                на компьютере Пользователя, который веб-клиент или веб-браузер каждый раз пересылает
                веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
            </p>
            <p>
                «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по
                протоколу IP.
            </p>
            <h2 class="policy__title">3. Права и обязанности субъектов персональных данных</h2>
            <p class="policy__list">3.1. Субъекты персональных данных вправе:</p>
            <ul>
                <li>получать доступ к информации, касающейся обработки их персональных данных;</li>
                <li>
                    требовать от Общества уточнения персональных данных, их блокирования или
                    уничтожения в случае, если персональные данные являются неполными, устаревшими,
                    неточными, незаконно полученными или не являются необходимыми для заявленной
                    цели обработки;
                </li>
                <li>
                    отозвать предоставленное Обществу согласие на обработку персональных данных;
                </li>
                <li>
                    обжаловать в суде или Роскомнадзоре любые неправомерные действия или бездействия
                    Общества при обработке и защите персональных данных, а также принимать иные
                    предусмотренные законом меры по защите своих прав.
                </li>
            </ul>
            <p class="policy__list">
                3.2. Субъект персональных данных имеет право на получение информации, касающейся
                обработки его персональных данных, содержащей:
            </p>
            <ul>
                <li>правовые основания и цели обработки персональных данных;</li>
                <li>применяемые Обществом способы обработки персональных данных;</li>
                <li>
                    наименование и место нахождения Общества, сведения о лицах, которые имеют доступ
                    к персональным данным или которым могут быть раскрыты персональные данные на
                    основании договора с Обществом или на основании федерального закона;
                </li>
                <li>
                    обрабатываемые персональные данные, относящиеся к соответствующему субъекту
                    персональных данных, источник их получения, если иной порядок представления
                    таких данных не предусмотрен федеральным законом;
                </li>
                <li>сроки обработки персональных данных, в том числе сроки их хранения;</li>
                <li>
                    порядок осуществления субъектом персональных данных прав, предусмотренных
                    законодательством о персональных данных;
                </li>
                <li>информацию о трансграничной передаче данных;</li>
                <li>
                    наименования или фамилии, имена, отчества и адреса лиц, осуществляющих обработку
                    персональных данных по поручению Общества, если обработка поручена или будет
                    поручена таким лицам;
                </li>
                <li>
                    информацию о способах реализации Обществом мер, направленных на обеспечение
                    выполнения Обществом обязанностей оператора персональных данных;
                </li>
                <li>подтверждение факта обработки персональных данных Оператором;</li>
                <li>
                    иные сведения, предусмотренные Законом о персональных данных или другими
                    федеральными законами.
                </li>
            </ul>
            <p class="policy__list">
                3.3. Субъект, Данные которого обрабатываются Оператором, обязан:
            </p>
            <ul>
                <li>
                    передавать достоверные, необходимые для достижения целей обработки, персональные
                    данные, а также подтверждать достоверность персональных данных предъявлением
                    оригиналов документов;
                </li>
                <li>
                    сообщать Оператору об уточнении (обновлении, изменении) своих персональных
                    данных;
                </li>
                <li>выполнять требования законодательства Российской Федерации.</li>
            </ul>
            <p>
                Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом
                субъекте персональных данных без согласия последнего, несут ответственность в
                соответствии с законодательством Российской Федерации.
            </p>
            <table>
                <thead>
                    <tr>
                        <td><p>Право субъекта</p></td>
                        <td><p>Способ реализации</p></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Право получения информации, касающейся обработки его Персональных
                            данных;
                        </td>
                        <td rowspan="2">
                            Направить письменный запрос в соответствии с п. 3.4. Политики на адрес:
                            127018, г. Москва, вн. тер. г. муниципальный округ Марьина Роща, ул.
                            Полковая, д. 3, стр. 1, этаж/помещение 3/I в порядке, установленном
                            ст.14 Закона.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Право на уточнение, блокировку или уничтожение данных, в случае если
                            Персональные данные являются неполными, устаревшими, неточными,
                            незаконно полученными или не являются необходимыми для заявленной цели
                            обработки;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Право отозвать предоставленное ранее согласие на обработку Персональных
                            данных.
                        </td>
                        <td>
                            Направить соответствующее уведомление на электронный адрес Оператора
                            info@answer-42.ru с пометкой «Отзыв согласия на обработку персональных
                            данных» в форме электронного документа, подписанного в соответствии с
                            федеральным законом электронной подписью, либо направить письменный
                            запрос в соответствии с п. 3.4. Политики на адрес: 127018, г. Москва,
                            вн. тер. г. муниципальный округ Марьина Роща, ул. Полковая, д. 3, стр.
                            1, этаж/помещение 3/I в порядке, установленном ст.14 Закона.
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="policy__list">
                3.4. Сведения об обработке персональных данных предоставляются субъекту персональных
                данных или его представителю при получении запроса субъекта персональных данных или
                его представителя. Запрос должен содержать номер основного документа,
                удостоверяющего личность субъекта персональных данных или его представителя,
                сведения о дате выдачи указанного документа и выдавшем его органе, сведения,
                подтверждающие участие субъекта персональных данных в отношениях с Обществом, либо
                сведения, иным образом подтверждающие факт обработки персональных данных Обществом.
                Кроме того, запрос должен быть подписан субъектом персональных данных или его
                представителем (при этом Запрос также должен содержать реквизиты документа,
                подтверждающего данные полномочия представителя).
            </p>
            <p class="policy__list">
                3.5. Если лицо, обратившееся в Общество с обращением или запросом, не уполномочено
                на получение информации, относящейся к персональным данным, соответствующему лицу
                отказывается в выдаче такой информации. Лицу, обратившемуся с соответствующим
                запросом, выдается уведомление об отказе в выдаче информации.
            </p>
            <p class="policy__list">
                3.6. Ответы на обращения и запросы о предоставлении сведений, раскрывающих детали
                обработки персональных данных, направляются в течение 3 (трех)- 10 (десяти) рабочих
                дней, в зависимости от темы запроса, с момента их поступления в Общество.
            </p>
            <p class="policy__list">
                3.7. В случае отзыва субъектом персональных данных согласия на обработку его данных
                Общество обязано прекратить обработку персональных данных и уничтожить их в течение
                10 (десяти) рабочих дней. Требования настоящего пункта не подлежат применению, если
                иное предусмотрено договором, стороной которого или выгодоприобретателем, по
                которому является субъект персональных данных, или действующим законодательством.
            </p>
            <h2 class="policy__title">4. Правовые основания обработки персональных данных.</h2>
            <p>
                Обработка персональных данных Оператором осуществляется в соответствии с полученным
                согласием на обработку персональных данных, в т.ч. предоставленным Пользователем
                сайта путем совершения конклюдентного действия (продолжения использования сайта),
                Уставом ООО «42», а также, если применимо, с осуществлением прав и законных
                интересов Общества.
            </p>
            <h2 class="policy__title">5. Цели обработки персональных данных.</h2>
            <p>
                Обработка персональных данных ограничивается достижением конкретных, заранее
                определенных и законных целей.
            </p>
            <p class="policy__catalog">
                Персональные данные соискателей на вакантные должности обрабатываются в целях:
            </p>
            <ul>
                <li>
                    проведения собеседования/интервью и последующего принятия решения о возможности
                    заключения трудового договора с лицами, претендующими на открытые вакансии;
                </li>
                <li>
                    включения соискателя в кадровый резерв для возможности его рассмотрения при
                    открытии аналогичных вакансий.
                </li>
            </ul>
            <p class="policy__catalog">
                Персональные данные Пользователей сайта обрабатываются в целях:
            </p>
            <ul>
                <li>понимания потребностей Пользователей сайта, улучшение работы сайта.</li>
            </ul>
            <h2 class="policy__title">
                6. Объем и категории обрабатываемых персональных данных, категории субъектов
                персональных данных
            </h2>
            <p class="policy__catalog">
                Оператор осуществляет обработку следующих персональных данных:
            </p>
            <p class="policy__catalog">в отношении Соискателей на вакантные должности:</p>
            <ul>
                <li>фамилия, имя, отчество;</li>
                <li>дата рождения, возраст;</li>
                <li>пол;</li>
                <li>сведения о гражданстве;</li>
                <li>адрес места жительства;</li>
                <li>номер телефона;</li>
                <li>адрес электронной почты;</li>
                <li>сведения об отношении к воинской обязанности;</li>
                <li>
                    сведения о трудовой деятельности (место работы и занимаемая должность; период
                    работы; должностные обязанности и достижения);
                </li>
                <li>
                    сведения об образовании и квалификации, в том числе сведения о профессиональной
                    переподготовке и (или) повышении квалификации (наименование учебной организации,
                    период обучения, специализация, реквизиты документа об образовании);
                </li>
                <li>сведения о ключевых навыках;</li>
                <li>сведения о владении иностранными языками;</li>
                <li>личное изображение (фотография/видеоизображение);</li>
                <li>сведения об интересах и достижениях;</li>
                <li>
                    сведения о рассматриваемой должности, желаемом формате работы и зарплатных
                    ожиданиях;
                </li>
                <li>
                    иные сведения, представленные соискателем в резюме и в процессе собеседования, в
                    том числе, проводимом в онлайн-форме с использованием видео-сервисов (без
                    проведения видеозаписи).
                </li>
            </ul>
            <p class="policy__catalog">в отношении Пользователей сайта:</p>
            <p class="policy__list">
                Файлы cookie, аналитика и сторонние технологии. Мы собираем данные с помощью файлов
                cookie, пикселей, тегов и аналогичных технологий (далее - «файлы cookie»). Файлы
                cookie — это небольшие текстовые файлы, которые веб-сервера размещают на вашем
                устройстве. Они осуществляют автоматический сбор информации, количественную оценку и
                анализ вашего использования сайта, а также идентифицируют веб-страницы и рекламные
                объявления, на которые вы переходите. Информация, которую мы получаем посредством
                файлов cookie, помогает нам совершенствовать ваш пользовательских опыт на сайте.
                Наши подрядчики и деловые партнеры могут использовать данную информацию для
                отображения рекламных объявлений на ваших устройствах с учетом ваших индивидуальных
                предпочтений и характеристик. Вы можете удалить файлы cookie и управлять настройками
                cookie в настройках своего веб-браузера. Однако некоторые файлы cookie не могут быть
                деактивированы, поскольку от них зависит корректная работа нашего Сайта.
            </p>
            <p class="policy__list">
                В ходе посещения Сайта автоматически может собираться следующая информация, которая
                не объединяется с иной информацией о посетителях Сайта и не относится к персональным
                данным:
            </p>
            <ul>
                <li>
                    техническая информация (включая IP-адрес, данные доступа, тип и версия браузера,
                    настройки часового пояса, тип и версия браузера, операционная система);
                </li>
                <li>
                    информация о посещении Сайта (включая URL, историю переходов на Сайт, на другие
                    ресурсы и обратно, поисковые запросы, время отклика страниц и ошибки загрузки,
                    продолжительность посещений отдельных страниц, информацию о взаимодействии со
                    страницей).
                </li>
            </ul>
            <h2 class="policy__title">7. Порядок и условия обработки персональных данных</h2>
            <p class="policy__list">
                7.1. Перечень действий, совершаемых Оператором с персональными данными субъектов:
                сбор, запись, систематизация, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передача (доступ), блокирование, удаление,
                уничтожение.
            </p>
            <p class="policy__list">
                7.2. Сайт не предназначен для обработки персональных данных несовершеннолетних. Если
                у Вас есть основания полагать, что несовершеннолетний предоставил нам свои
                персональных данные через сайт, то просим Вас сообщить нам об этом, написав на почту
                info@answer-42.ru
            </p>
            <p class="policy__list">
                7.3. Обработка Оператором специальных и биометрических категорий персональных данных
                не осуществляется в рамках целей, определенных настоящей Политикой.
            </p>
            <p class="policy__list">7.4 Оператор не осуществляет трансграничную передачу Данных.</p>
            <p class="policy__list">
                7.5. Оператор вправе передавать персональные данные органам дознания и следствия,
                иным уполномоченным органам по основаниям, предусмотренным действующим
                законодательством Российской Федерации. Оператор и иные лица, получившие доступ к
                персональным данным, обязаны не раскрывать третьим лицам и не распространять
                персональные данные без согласия субъекта персональных данных, если иное не
                предусмотрено законодательством Российской Федерации.
            </p>
            <p class="policy__list">
                7.6. Условием прекращения обработки Данных может являться достижение целей обработки
                персональных данных, истечение срока действия согласия или отзыв согласия субъекта
                персональных данных на обработку его Данных, а также выявление неправомерной
                обработки Данных.
            </p>
            <h2 class="policy__title">8. Передача персональных данных</h2>
            <p class="policy__list">
                8.1. Общество осуществляет передачу Данных Пользователей сайта в рамках
                использования сервиса Яндекс. Метрика.
            </p>
            <p class="policy__list">
                8.2. При передаче персональных данных третьим лицам Общество уведомляет указанных
                лиц об обязанности сохранения конфиденциальности персональных данных и использования
                их лишь в тех целях, для которых они переданы. При передаче персональных данных
                лицам, обрабатывающим эти данные по поручению (в интересах) Общества, в соглашении с
                таким лицом устанавливаются (предусматриваются):
            </p>
            <ul>
                <li>перечень передаваемых персональных данных;</li>
                <li>
                    перечень действий (операций) с персональными данными, которые будут совершаться
                    лицом, осуществляющим обработку персональных данных;
                </li>
                <li>допустимые цели обработки данных таким лицом;</li>
                <li>
                    обязанность такого лица соблюдать конфиденциальность персональных данных и
                    обеспечивать безопасность персональных данных при их обработке;
                </li>
                <li>конкретные требования к защите обрабатываемых персональных данных;</li>
                <li>
                    обязанность такого лица осуществлять обработку персональных данных граждан
                    Российской Федерации с использованием баз данных, находящихся на территории
                    Российской Федерации;
                </li>
                <li>
                    обязанность такого лица принимать меры, направленные на обеспечение выполнения
                    обязанностей, возложенных на оператора законодательством о персональных данных;
                </li>
                <li>
                    обязанность такого лица предоставлять документы и иную информацию,
                    подтверждающие принятие мер и соблюдение требований в соответствии с
                    законодательством о персональных данных и поручением Общества.
                </li>
            </ul>
            <h2 class="policy__title">9. Способы обработки персональных данных</h2>
            <p class="policy__list">
                9.1. Оператор использует смешанный способ обработки персональных данных
                (неавтоматизированная обработка персональных данных и автоматизированная обработка
                персональных данных с передачей по внутренней сети оператора). Информация о
                персональных данных доступна лишь для строго определенных работников.
            </p>
            <h2 class="policy__title">10. Сроки обработки и хранения Данных</h2>
            <p class="policy__list">
                10.1. Сроки обработки и хранения Данных определяются исходя из целей обработки
                Данных, в соответствии со сроком действия договора с субъектом Данных, требованиями
                федеральных законов, сроками исковой давности. Данные, срок обработки которых истек,
                должны быть уничтожены, если иное не предусмотрено законодательством Российской
                Федерации. Хранение Данных после прекращения их обработки допускается только после
                их обезличивания.
            </p>
            <p class="policy__list">
                10.2. При осуществлении обработки персональных данных Оператор использует базы
                данных, находящиеся на территории Российской Федерации.
            </p>
            <h2 class="policy__title">11. Меры для защиты Данных</h2>
            <p>
                Поскольку к настоящей Политике в соответствии с ч. 2 ст. 18.1 Федерального закона №
                152-ФЗ «О персональных данных» необходимо обеспечить неограниченный доступ, в ней не
                публикуется детальная информация о принятых мерах по защите персональных данных у
                Оператора, а также иная информация, использование которой неограниченным кругом лиц
                может нанести ущерб Оператору или субъектам персональных данных.
            </p>
            <p>
                Оператор при обработке Данных принимает необходимые правовые, организационные и
                технические меры для защиты Данных от неправомерного и/или несанкционированного
                доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления,
                распространения Данных, а также от иных неправомерных действий в отношении Данных.
            </p>
            <p>К таким мерам, в частности, относятся:</p>
            <ul>
                <li>назначение лица, ответственного за организацию обработки Данных;</li>
                <li>
                    разработка и утверждение локальных актов по вопросам обработки и защиты Данных;
                </li>
                <li>
                    обеспечение физической безопасности помещений и средств обработки Данных:
                    запираемые на ключ места хранения, пропускной режим, охрана, видеонаблюдение;
                </li>
                <li>
                    ограничение и разграничение доступа к Данным и средствам обработки, мониторинг
                    действий с Данными;
                </li>
                <li>
                    соблюдение условий, исключающих несанкционированный доступ к материальным
                    носителям Данных и обеспечивающих сохранность Данных;
                </li>
                <li>
                    применение правовых, организационных и технических мер по обеспечению
                    безопасности Данных:
                    <ul>
                        <li>
                            определение угроз безопасности Данных при их обработке в информационных
                            системах персональных данных;
                        </li>
                        <li>
                            применение организационных и технических мер по обеспечению безопасности
                            Данных при их обработке в информационных системах персональных данных,
                            необходимых для выполнения требований к защите Данных, исполнение
                            которых обеспечивает установленные Правительством Российской Федерации
                            уровни защищенности Данных;
                        </li>
                        <li>применение средств защиты информации;</li>
                        <li>
                            оценка эффективности принимаемых мер по обеспечению безопасности Данных
                            до ввода в эксплуатацию информационной системы персональных данных;
                        </li>
                        <li>
                            учет машинных носителей Данных, если хранение Данных осуществляется на
                            машинных носителях;
                        </li>
                        <li>
                            обнаружение фактов несанкционированного доступа к Данным и принятие мер
                            по недопущению подобных инцидентов в дальнейшем;
                        </li>
                        <li>
                            резервное копирование информации для возможности восстановления Данных,
                            при их модификации или уничтожении вследствие несанкционированного
                            доступа к ним;
                        </li>
                        <li>
                            установление правил доступа к Данным, обрабатываемым в информационной
                            системе персональных данных, а также обеспечение регистрации и учета
                            всех действий, совершаемых с Данными в информационной системе
                            персональных данных.
                        </li>
                    </ul>
                </li>
                <li>
                    контроль за принимаемыми мерами по организации обработки и обеспечению
                    безопасности Данных;
                </li>
                <li>
                    оценка вреда, который может быть причинен субъектам Данных в случае нарушения
                    требований Закона, соотношение указанного вреда и принимаемых Оператором мер,
                    направленных на обеспечение выполнения обязанностей, предусмотренных
                    законодательством Российской Федерации;
                </li>
                <li>
                    ознакомление Работников Оператора, непосредственно осуществляющих обработку
                    Данных, с положениями законодательства Российской Федерации о Данных, в том
                    числе с требованиями к защите Данных, локальными актами по вопросам обработки и
                    защиты Данных, и обучение Работников Оператора.
                </li>
            </ul>
            <h2 class="policy__title">12. Изменения Политики</h2>
            <p class="policy__list">
                12.1. Общество может периодически изменять настоящую Политику. Изменения Политики не
                имеют обратной силы. Сведения о дате последнего изменения Политики отображаются в
                начале настоящей Политики.
            </p>
        </div>
    </div>
</div>
