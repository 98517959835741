<div class="container">
  <div class="wrap">
    <h2 class="title title__white remote-title">Удаленная работа</h2>
    <app-cities></app-cities>
    <div class="edge">
      <div class="item">
        <h3 class="item__title">Умеем, практикуем</h3>
        <p class="item__text">работали с удалёнкой до того, как это стало мейнстримом - затачиваем процессы так, чтобы было удобно</p>
      </div>
      <div class="item">
        <h3 class="item__title">Покупаем железо</h3>
        <p class="item__text">нужно оборудование? Мы купим и отправим тебе домой. Когда необходимость в нём отпадёт, заберём обратно</p>
      </div>
      <div class="item">
        <h3 class="item__title">Не выходя из дома</h3>
        <p class="item__text">привезём и заберём все официальные документы, которые нужно подписать</p>
      </div>
      <div class="item">
        <h3 class="item__title">Вместе веселимся</h3>
        <p class="item__text">привозим за наш счёт на праздники, организуем встречи удалёнщиков из одного города</p>
      </div>
    </div>
  </div>
</div>

