import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ReportService } from 'src/app/services/report.service copy';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementComponent implements OnInit {
  @Output() closeAgreement = new EventEmitter();
  sendingForm: UntypedFormGroup;
  isAgree = false;
  isAgree2 = false;
  subscription = new Subscription();
  $isShowPolicy = this.policyService.isShowPolicyModal;
  constructor(
    private policyService: PolicyService,
    private messageService: MessageService,
    private reportservice: ReportService,
  ) {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '15px';
  }

  ngOnInit(): void {
    this._createForm();
    this.subscription.add(
      this.sendingForm.controls['phone'].valueChanges.subscribe((res) => {
        if (res.length > 20) {
          this.sendingForm.patchValue({ phone: res.substring(0, 20) });
        }
        if (res.match(/[^0-9\-\(\)\+ ]/g)) {
          this.sendingForm.controls['phone'].setValue(res.replace(/[^0-9\-\(\)\+ ]/g, ''));
        }
        if (res.length == 0) {
          this.sendingForm.controls['phone'].setValue('+7 (');
        }
      }),
    );
  }

  send(): void {
    if (this.sendingForm.status === 'INVALID') {
      return;
    }
    this.messageService
      .sendAgeementToDb({
        name: this.sendingForm.value.name,
        surname: this.sendingForm.value.surname,
        email: this.sendingForm.value.email,
        phone: this.sendingForm.value.phone,
      })
      .subscribe({
        next: () => {
          this.reportservice.message.next({
            type: 'note',
            title: '',
            text: 'Согласие успешно отправлено',
          });
          this.close();
        },
        error: () => {
          this.reportservice.message.next({
            type: 'error',
            title: '',
            text: 'Согласие не отправлено, попробуйте снова',
          });
        },
      });
  }

  showPolicy(): void {
    this.$isShowPolicy.next(true);
  }

  addShieldToSymbol(text: string): string {
    if (text) {
      return text.replace(/[_*`\[]/g, '\\$&');
    } else {
      return '';
    }
  }

  close(): void {
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0px';
    this.closeAgreement.emit();
    this.subscription.unsubscribe();
  }

  private _createForm(): void {
    this.sendingForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.min(2)]),
      surname: new UntypedFormControl(null, [Validators.required, Validators.min(2)]),
      email: new UntypedFormControl(null, [Validators.email]),
      phone: new UntypedFormControl(null, [
        Validators.pattern(/^((\+?\d?)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,18}$/),
        Validators.required,
      ]),
    });
  }

  focusOnPhone(): void {
    if (!this.sendingForm.value['phone']) {
      this.sendingForm.controls['phone'].setValue('+7 (');
    }
  }

  manualChangeDate(e: KeyboardEvent): void {
    if (e.key === 'Backspace') {
      // удаление символов
      return;
    }
    if (this.sendingForm.value['phone'].length === 2) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + ' (');
    }
    if (this.sendingForm.value['phone'].length === 7) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + ') ');
    }
    if (this.sendingForm.value['phone'].length === 12) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + '-');
    }
    if (this.sendingForm.value['phone'].length === 15) {
      this.sendingForm.controls['phone'].setValue(this.sendingForm.value['phone'] + '-');
    }
  }
}
