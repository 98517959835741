import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Cities } from 'src/app/models/cities.model';
import { CitiesService } from 'src/app/services/cities.service';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})



export class CitiesComponent implements OnDestroy {

  public isShow = false;
  public cities: Observable<Cities>;
  public isSmallScreen = false;
  private subscription: Subscription;
  private subscriptionBreakpoint: Subscription;

  @ViewChild('map') map: ElementRef;

  constructor(
    private citiesService: CitiesService,
    private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver) {
      this.cities = this.citiesService.getCities();
      this.subscription = fromEvent(window, 'scroll').subscribe((scroll) => {
        this.getWidth();
       });
      this.subscriptionBreakpoint = this.breakpointObserver.observe('(max-width: 770px)').subscribe((res) => {
        if (res.matches) {
          this.isSmallScreen = true;
        } else{
          this.isSmallScreen = false;
        }
        this.ref.markForCheck();
      });
     }

  private getWidth(): void {
    if (window.pageYOffset >= this.map.nativeElement.offsetTop - window.innerHeight) {
      this.isShow = true;
      this.ref.markForCheck();
    }
  }

  ngOnDestroy(): void {
      this.subscriptionBreakpoint.unsubscribe();
      this.subscription.unsubscribe();
  }
}
