import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderComponent {
  customOptions: OwlOptions = {
    margin: 15,
    nav: false,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    responsive: {
        0: {
          items: 1,
          stagePadding: 32,
        },
        550: {
          center: false,
          items: 2,
          stagePadding: 45,
        },
        771: {
          items: 0
        }
    }
  };
}
