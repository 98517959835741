<div class="container">
    <h2 class="title title__black">Наши офисы</h2>
    <div class="offices">
        <ng-container *ngTemplateOutlet="slide1"></ng-container>
        <ng-container *ngTemplateOutlet="slide2"></ng-container>
        <ng-container *ngTemplateOutlet="slide3"></ng-container>
    </div>

    <div>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <ng-container *ngTemplateOutlet="slide1"></ng-container>
            </ng-template>
            <ng-template carouselSlide>
                <ng-container *ngTemplateOutlet="slide2"></ng-container>
            </ng-template>
            <ng-template carouselSlide>
                <ng-container *ngTemplateOutlet="slide3"></ng-container>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>

<div class="footer"></div>

<app-modal
    *ngIf="isShowModal"
    [isProject]="false"
    (closeModal)="isShowModal = !isShowModal"
></app-modal>

<ng-template #slide1>
    <div class="office">
        <img class="office__img" src="assets/office-moscow.png" />
        <h3 class="office__title">Москва</h3>
        <a
            class="office__address"
            target="_blank"
            href="https://yandex.ru/maps/org/42/236634646916/?ll=37.603749%2C55.798386&z=14.31"
        >
            ул. Полковая, д. 3, стр. 1
        </a>
    </div>
</ng-template>
<ng-template #slide2>
    <div class="office">
        <img class="office__img" src="assets/office-samara.png" />
        <h3 class="office__title">Самара</h3>
        <a
            class="office__address"
            target="_blank"
            href="https://2gis.ru/samara/firm/70000001060684001?m=50.145359%2C53.204981%2F16"
        >
            ш. Московское, д. 4, стр. 9
        </a>
    </div>
</ng-template>
<ng-template #slide3>
    <div class="office">
        <img class="office__img" src="assets/office-perm.png" />
        <h3 class="office__title">Пермь</h3>
        <a
            class="office__address"
            target="_blank"
            href="https://2gis.ru/perm/firm/70000001060684298?m=56.251818%2C58.018514%2F16"
        >
            ул. Советская, д. 3
        </a>
    </div>
</ng-template>
