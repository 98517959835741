<h2 class="title title__black order-title">Почему <span class="title__accent">госзаказ у нас</span> — это не страшно</h2>
<div class="content">
  <div class="content__description">
    <ng-container *ngTemplateOutlet="slide1" ></ng-container>
    <ng-container *ngTemplateOutlet="slide2" ></ng-container>
    <ng-container *ngTemplateOutlet="slide3" ></ng-container>
    <ng-container *ngTemplateOutlet="slide4" ></ng-container>
  </div>
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide>
      <ng-container *ngTemplateOutlet="slide1" ></ng-container>
    </ng-template>
    <ng-template carouselSlide>
      <ng-container *ngTemplateOutlet="slide2" ></ng-container>
    </ng-template>
    <ng-template carouselSlide>
      <ng-container *ngTemplateOutlet="slide3" ></ng-container>
    </ng-template>
    <ng-template carouselSlide>
      <ng-container *ngTemplateOutlet="slide4" ></ng-container>
    </ng-template>
  </owl-carousel-o>
</div>



<ng-template #slide1>
  <div class="why">
    <h3 class="why__title">Скорее продукты, чем проекты</h3>
    <p class="why__text">заказчик формирует требования к атомарным продуктам в составе общей экосистемы. Мы делаем некоторые из этих продуктов</p>
    <img class="why__img" src="assets/happy-pc-1.png">
  </div>
</ng-template>
<ng-template #slide2>
  <div class="why">
    <h3 class="why__title">Мало бюрократии</h3>
    <p class="why__text">мы выносим формальные процессы в отдельные треки, чтобы они минимально влияли на команды разработки</p>
    <img class="why__img" src="assets/happy-pc-2.png">
  </div>
</ng-template>
<ng-template #slide3>
  <div class="why">
    <h3 class="why__title">Современные технологии</h3>
    <p class="why__text">ищем, предлагаем, внедряем. Стек не закостенел, все заинтересованы в лучших решениях</p>
    <img class="why__img" src="assets/happy-pc-3.png">
  </div>
</ng-template>
<ng-template #slide4>
  <div class="why">
    <h3 class="why__title">Это правда важно</h3>
    <p class="why__text">без преувеличения, некоторые наши продукты помогают спасать жизни, а другие — лучше учить детей</p>
    <img class="why__img" src="assets/happy-pc-4.png">
  </div>
</ng-template>