<div class="container">
    <div class="container__left">
        <h1 class="title">Привет! <span class="title__accent">Мы 42</span></h1>
        <p class="text">
            Да-да, это именно то, что вы подумали —
            <br />
            <a
                target="_blank"
                href="https://ru.wikipedia.org/wiki/%D0%9E%D1%82%D0%B2%D0%B5%D1%82_%D0%BD%D0%B0_%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D1%8B%D0%B9_%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81_%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8,_%D0%B2%D1%81%D0%B5%D0%BB%D0%B5%D0%BD%D0%BD%D0%BE%D0%B9_%D0%B8_%D0%B2%D1%81%D0%B5%D0%B3%D0%BE_%D1%82%D0%B0%D0%BA%D0%BE%D0%B3%D0%BE"
                class="text-decoration-underline"
            >
                ответ на главный вопрос жизни, Вселенной и всего такого.</a
            >
            <br />
            На самом деле мы IT-компания, которая делает софт для органов
            <br />
            власти и частных клиентов. Звучит серьезно, но мы не всегда такие
        </p>
    </div>
    <div class="container__right">
        <img class="main-picture" src="assets/main-robot.png" />
    </div>
</div>

<app-modal
    *ngIf="isShowModal"
    [isProject]="isProject"
    (closeModal)="isShowModal = !isShowModal"
></app-modal>
