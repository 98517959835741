<div #map class="wrap">
  <div *ngIf="cities | async as cities" class="map" [ngStyle]="{'height': isSmallScreen? '35rem' : cities.viewportHeight}">
    <ng-container *ngIf="isShow">
      <div #city *ngFor="let city of cities.cities" 
        [ngStyle]="{'top': city.position.top, 'left': city.position.left, 'font-size': city.fontSize, 'animation-delay': city.delay + 's'}" 
        [ngClass]="{'city__favorite': city.isFavorite && isSmallScreen, 'city__mobile': isSmallScreen}"
        class="city show">
        <svg *ngIf="city.isFavorite && !isSmallScreen" width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.3101 9.29931L17.3172 8.07839L13.7347 0.447395C13.4671 -0.122439 12.5322 -0.122439 12.2646 0.447395L8.68308 8.07839L0.690245 9.29931C0.033745 9.40006 -0.228422 10.1985 0.231995 10.6697L6.03866 16.6216L4.66608 25.0358C4.55666 25.7042 5.27058 26.2058 5.861 25.8776L13.0002 21.9321L20.1393 25.8786C20.7243 26.2036 21.4447 25.7118 21.3342 25.0369L19.9617 16.6226L25.7683 10.6708C26.2287 10.1985 25.9655 9.40006 25.3101 9.29931Z" fill="white"/>
        </svg>
        {{city.name}}
      </div>
    </ng-container>
  </div>
</div>
