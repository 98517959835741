import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsComponent {
  public isSmallScreen = false;

  customOptions: OwlOptions = {
    center: true,
    loop: true,
    margin: 10,
    nav: false,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    // autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      1601: {
        items: 1,
        stagePadding: 130,
        margin: 20,
      },
      1700: {
        items: 1,
        stagePadding: 150,
        margin: 50,
      },
      1930: {
        items: 1,
        stagePadding: 250,
        margin: 150,
      },
    },
  };

  constructor(private breakpointObserver: BreakpointObserver, private ref: ChangeDetectorRef) {
    this.breakpointObserver.observe('(max-width: 770px)').subscribe((res) => {
      if (res.matches) {
        this.isSmallScreen = true;
      } else {
        this.isSmallScreen = false;
      }
      this.ref.markForCheck();
    });
  }
}
