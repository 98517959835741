import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyComponent {
  @Input() style: 'modal' | 'text' = 'modal';
  @Output() showAgreement = new EventEmitter();
  $isShowPolicy = this.policyService.isShowPolicyModal;
  constructor(private policyService: PolicyService) {}
}
