<div *ngIf="isShow" class="container container--{{ message?.size }} container__{{ message?.type }}">
    <div class="msg-header msg-header__{{ message?.type }}">
        <mat-icon
            *ngIf="message?.type === 'error'"
            class="msg-header__error"
            svgIcon="error"
        ></mat-icon>
        <mat-icon
            *ngIf="message?.type === 'note'"
            class="msg-header__note"
            svgIcon="note"
        ></mat-icon>
        <mat-icon
            *ngIf="message?.type === 'warning'"
            class="msg-header__warning"
            svgIcon="warning"
        ></mat-icon>
        <button class="msg-header__close empty-btn" (click)="isShow = false">
            <mat-icon svgIcon="cross" class="msg-header__{{ message?.type }}"></mat-icon>
        </button>
    </div>
    <p class="text text__{{ message?.type }}">
        <span *ngIf="message?.type === 'error'"> Ошибка: </span>
        <span *ngIf="message?.type === 'warning'"> Внимание: </span>
        <span *ngIf="message?.type === 'note'"> Уведомление: </span>
        <span *ngIf="message?.text" class="text text__{{ message?.type }}"
            >Мы коварно используем куки. Подробная информация размещена по
            <a routerLink="policy">ссылке</a>. Вы можете запретить нам их использовать, за
            исключением обязательных, установив ограничения в браузере</span
        >
    </p>
</div>
