<div class="container">
    <app-header></app-header>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>

<app-policy *ngIf="isShowPolicy | async"></app-policy>
<app-report></app-report>
