<div
    #el
    *ngIf="!isHideMenu"
    class="wrap"
    [ngClass]="{ wrap__sticky: isStickyHeader || isShowMenu }"
>
    <div class="container">
        <a routerLink="/">
            <img
                class="logo"
                [ngClass]="{ logo__sticky: isStickyHeader }"
                src="assets/logo.png"
                alt="Логотип"
            />
        </a>
        <div *ngIf="!isSmallScreen">
            <ng-container *ngTemplateOutlet="nav"></ng-container>
        </div>
        <div *ngIf="isSmallScreen">
            <ng-container *ngTemplateOutlet="burger"></ng-container>
        </div>
    </div>
</div>
<div *ngIf="isShowMenu">
    <ng-container *ngTemplateOutlet="nav"></ng-container>
</div>

<app-modal
    *ngIf="isShowModal"
    [isProject]="isProject"
    (closeModal)="isShowModal = !isShowModal"
></app-modal>

<ng-template #nav>
    <div *ngIf="isShowMenu && isSmallScreen">
        <ng-container *ngTemplateOutlet="burger"></ng-container>
    </div>
    <div class="menu">
        <nav class="top-nav">
            <a
                class="top-nav__link"
                [routerLink]="['/']"
                fragment="about"
                (click)="isShowMenu = false"
                >О нас</a
            >
            <a
                class="top-nav__link"
                [routerLink]="['/']"
                fragment="products"
                (click)="isShowMenu = false"
                >Продукты</a
            >
            <a
                class="top-nav__link"
                [routerLink]="['/']"
                fragment="for-the-alliance"
                (click)="isShowMenu = false"
                >Госзаказ</a
            >
            <a
                class="top-nav__link"
                [routerLink]="['/']"
                fragment="offices"
                (click)="isShowMenu = false"
                >Офисы</a
            >
            <a
                class="top-nav__link"
                [routerLink]="['/']"
                fragment="remote"
                (click)="isShowMenu = false"
                >Удалёнка</a
            >
            <a
                class="top-nav__link"
                [routerLink]="['/']"
                fragment="working-conditions"
                (click)="isShowMenu = false"
                >Условия работы</a
            >
            <a routerLink="documents" class="top-nav__link" (click)="isShowMenu = false"
                >Документы</a
            >
        </nav>
        <!-- <div *ngIf="isSmallScreen" ngClass="bottom-nav">
            <button
                (click)="isShowModal = !isShowModal; isProject = false"
                class="main-button main-button__black"
                (click)="isShowMenu = false"
            >
                Карьера в 42
            </button>
        </div> -->
    </div>
</ng-template>
<ng-template #burger>
    <div class="menu-burger-wrapper">
        <button (click)="this.isShowMenu = !this.isShowMenu" class="menu-burger">
            <div *ngIf="!isShowMenu" class="menu-burger__line"></div>
            <div *ngIf="!isShowMenu" class="menu-burger__line"></div>
            <svg
                *ngIf="isShowMenu"
                class="cross"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="2.35693"
                    width="25"
                    height="3.33333"
                    transform="rotate(45 2.35693 0)"
                    fill="#323232"
                />
                <rect
                    x="0.323975"
                    y="18.0363"
                    width="25"
                    height="3.33333"
                    transform="rotate(-45 0.323975 18.0363)"
                    fill="#323232"
                />
            </svg>
        </button>
    </div>
</ng-template>
