<div class="container">
    <button class="main-button" (click)="isShowAgreement = !isShowAgreement">Дать согласие</button>
    <p>
        Физическое лицо, отправляя заявку на этом веб-сайте через форму предоставления согласия,
        действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность,
        предоставляет свое согласие на обработку персональных данных (далее – Согласие) Обществу с
        ограниченной ответственностью «42» (адрес: 127018, г. Москва, вн. тер. г. муниципальный
        округ Марьина Роща, ул. Полковая, д. 3, стр. 1, этаж/помещение 3/I), как без использования
        средств автоматизации, так и с их использованием на следующих условия:
    </p>
    <ol>
        <li>
            Цель обработки персональных данных: подбор персонала с осуществлением информационного и
            организационного взаимодействия с соискателями, а также включение соискателя в кадровый
            резерв.
        </li>
        <li>
            Согласие предоставляется на обработку следующих моих персональных данных, указанных в
            т.ч. в резюме:
            <ul>
                <li>фамилия, имя, отчество;</li>
                <li>дата рождения, возраст;</li>
                <li>пол;</li>
                <li>сведения о гражданстве;</li>
                <li>адрес места жительства;</li>
                <li>номер телефона;</li>
                <li>адрес электронной почты;</li>
                <li>сведения об отношении к воинской обязанности;</li>
                <li>
                    сведения о трудовой деятельности (место работы и занимаемая должность; период
                    работы; должностные обязанности и достижения);
                </li>
                <li>
                    сведения об образовании и квалификации, в том числе сведения о профессиональной
                    переподготовке и (или) повышении квалификации (наименование учебной организации,
                    период обучения, специализация, реквизиты документа об образовании);
                </li>
                <li>сведения о ключевых навыках;</li>
                <li>cведения о владении иностранными языками;</li>
                <li>личное изображение (фотография/видеоизображение);</li>
                <li>сведения об интересах и достижениях;</li>
                <li>
                    сведения о рассматриваемой должности, желаемом формате работы и зарплатных
                    ожиданиях;
                </li>
                <li>
                    сведения, представленные соискателем в процессе собеседования, в том числе,
                    проводимом в онлайн-форме с использованием видео-сервисов (без проведения
                    видеозаписи).
                </li>
            </ul>
        </li>
        <li>
            С персональными данными могут быть совершены следующие действия: сбор; запись;
            систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение;
            использование; блокирование; удаление; уничтожение.
        </li>
        <li>
            Персональные данные обрабатываются до истечения 60 дней с момента принятия решения о
            соответствии/несоответствии соискателя требованиям по вакантным должностям, на которые
            рассматривается соискатель. При включении соискателя в кадровый резерв, обработка его
            персональных данных будет прекращена по истечении 10 лет с момента его включения в
            кадровый резерв.
        </li>
        <li>
            Согласие может быть отозвано путем направления ООО «42» письменного заявления по адресу,
            указанному выше, или электронного заявления, подписанного согласно законодательству
            Российской Федерации в области электронной подписи, по адресу info&#64;answer-42.ru.
        </li>
        <li>
            В случае отзыва Согласия ООО «42» вправе продолжить обработку персональных данных без
            него при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи
            10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006
            г.
        </li>
        <li>
            Согласие действует все время до момента прекращения обработки персональных данных,
            указанных в п. 4- 6 Согласия.
        </li>
    </ol>
    <button class="main-button" (click)="isShowAgreement = !isShowAgreement">Дать согласие</button>
</div>
<app-agreement
    *ngIf="isShowAgreement"
    (closeAgreement)="isShowAgreement = !isShowAgreement"
></app-agreement>
