import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cities } from '../models/cities.model';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(private http: HttpClient ) { }

  getCities(): Observable<Cities> {
    return this.http.get<Cities>('assets/mappy.json');
  }
}
